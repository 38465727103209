import React, {useRef} from "react";
import Layout from "@components/layout";
import {useIsVisible} from "react-is-visible";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useMediaQuery from "use-media-query-hook";
import {Link} from "gatsby";
/* globals ROUTES */
const headers = [
	{
		name: "Use of Cookies",
		href: "useOfCookies",
	},
	{
		name: "Control of Cookies?",
		href: "controlOfCookies",
	},
	{
		name: "Other Tracking Technologies",
		href: "otherTrackingTechnologies",
	},
	{
		name: "Privacy Policy",
		href: "privacyPolicy",
	},
];

const CookiesPrivacyPage = () => {
	const headerRef = useRef();
	const isHeaderVisible = useIsVisible(headerRef);

	const bottomRef = useRef();
	const isBottomVisible = useIsVisible(bottomRef);

	const isMobile = useMediaQuery("(max-width: 850px)");

	return (
		<Layout
			pageTitle="Cookie Policy"
			footerRef={bottomRef}
			headerBg="bg-purple-50">
			<section className="relative bg-purple-50 py-5" ref={headerRef}>
				<div className="relative container pt-12 px-4 mb-20 mx-auto text-center">
					<h2 className="mt-8 mb-8 lg:mb-12 text-3xl lg:text-4xl font-semibold">
						Platnova Cookies Policy
					</h2>
				</div>
			</section>

			<section className="relative">
				<div className="container mx-auto px-4">
					<div className="flex flex-wrap -mx-3">
						<div className="w-full sm:w-1/3 px-3 pt-10 lg:pt-20 pb-10">
							<ul
								className={`mr-4 md:mr-0 mb-4 
								${!isMobile ? (isHeaderVisible ? "" : "fixed top-20") : ""} 
								${isBottomVisible ? "hidden" : ""}`}>
								{headers.map((item, index) => (
									<li className="mb-5" key={index}>
										<AnchorLink
											offset="80"
											className="inline-block pl-4 text-sm font-medium"
											href={`#${item.href}`}>
											{item.name}
										</AnchorLink>
									</li>
								))}
							</ul>
						</div>
						<div className="w-full sm:w-2/3 px-3">
							<div className="flex flex-wrap px-3">
								<div className="mt-8 mb-8">
									Platnova (“we” or “us” or “our”) may use cookies, web beacons,
									tracking pixels, and other tracking technologies when you
									visit our website, including any other media form, media
									channel, mobile website, or mobile application related or
									connected thereto (collectively, the “Site”) to help customize
									the Site and improve your experience.
								</div>

								<div className="mb-3">
									We reserve the right to make changes to this Cookie Policy at
									any time and for any reason. We will alert you about any
									changes by updating the “Last Updated” date of this Cookie
									Policy.
								</div>
								<div className="mb-8">
									Any changes or modifications will be effective immediately
									upon posting the updated Cookie Policy on the Site, and you
									waive the right to receive specific notice of each such change
									or modification
								</div>

								<div className="mb-8">
									You are encouraged to periodically review this Cookie Policy
									to stay informed of updates. You will be deemed to have been
									made aware of, will be subject to, and will be deemed to have
									accepted the changes in any revised Cookie Policy by your
									continued use of the Site after the date such revised Cookie
									Policy is posted.
								</div>

								<div className="mb-8" id="useOfCookies">
									<h2 className="font-bold mb-3">Use of Cookies</h2>
									<div className="mb-5">
										A “cookie” is a string of information which assigns you a
										unique identifier that we store on your computer. Your
										browser then provides that unique identifier to use each
										time you submit a query to the Site. We use cookies on the
										Site to, among other things, keep track of services you have
										used, record registration information, record your user
										preferences, keep you logged into the Site, facilitate
										purchase procedures, and track the pages you visit. Cookies
										help us understand how the Site is being used and improve
										your user experience
									</div>
								</div>

								<div className="mb-8" id="controlOfCookies">
									<h2 className="font-bold mb-3">Control of Cookies?</h2>
									<div className="mb-5">
										Most browsers are set to accept cookies by default. However,
										you can remove or reject cookies in your browser’s settings.
										Please be aware that such action could affect the
										availability and functionality of the Site
									</div>
								</div>

								<div className="mb-8" id="otherTrackingTechnologies">
									<h2 className="font-bold mb-3">
										Other Tracking Technologies
									</h2>
									<div className="mb-5">
										In addition to cookies, we may use web beacons, pixel tags,
										and other tracking technologies on the Site to help
										customize the Site and improve your experience. A “web
										beacon” or “pixel tag” is tiny object or image embedded in a
										web page or email.
									</div>

									<div className="mb-3">
										They are used to track the number of users who have visited
										particular pages and viewed emails, and acquire other
										statistical data. They collect only a limited set of data,
										such as a cookie number, time and date of page or email
										view, and a description of the page or email on which they
										reside. Web beacons and pixel tags cannot be declined.
										However, you can limit their use by controlling the cookies
										that interact with them.
									</div>
								</div>

								<div className="mb-8" id="privacyPolicy">
									<h2 className="font-bold mb-3">Privacy Policy</h2>
									<div className="mb-5">
										For more information about how we use information collected
										by cookies and other tracking technologies, please refer to
										our{" "}
										<Link
											className="font-semibold underline"
											to={ROUTES.privacyPolicy.path}>
											Privacy Policy
										</Link>
										. This Cookie Policy is part of and is incorporated into our
										Privacy Policy. By using the Site, you agree to be bound by
										this Cookie Policy and our Privacy Policy.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default CookiesPrivacyPage;
